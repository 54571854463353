import React from 'react';
import { ChakraProvider, CSSReset, extendTheme } from '@chakra-ui/react';
import { ThemeProvider } from './src/context/ThemeContext';

import { Fonts } from './src/theme.js';
import './src/assets/sass/index.scss';

const customTheme = extendTheme({
  fonts: {
    heading: 'Lato',
    body: 'Open Sans',
  },
  components: {
    Button: { baseStyle: { _focus: { boxShadow: 'none' } } },
  },
  styles: {
    global: {
      link: {
        variants: {
          linkOne: {
            color: 'brand.four',
            fontWeight: 'bold',
          },
        },
      },
      h1: {
        textTransform: 'uppercase',
        fontWeight: 'light',
      },
      h2: {
        textTransform: 'uppercase',
        fontWeight: 'semibold',
      },
      h4: {
        variants: {
          flare: {
            color: 'flare',
            fontWeight: 'bold',
          },
        },
      },
      h5: {},
      variants: {
        flare: {
          color: 'teal.500',
          fontWeight: 'bold',
        },
      },
      body: {
        minHeight: '100vh',
      },
      a: {
        color: 'teal.500',
      },
    },
  },
  colors: {
    black: '#1D1D1E',
    white: '#F0F2FA', // '#E8ECFA', //'#F5F8FC',
    brand: {
      dark: '#0A043C', // dark navy blue
      light: '#7394B8', // light steal blue
      one: '#BFAEAE', // light red tope // '#C37777', //
      two: '#546A5C', // green shade
      three: '#AB9B9B', // dark red tope
      four: '#A84040', // dark red tope //'#D65151', // light red tope
      five: '#EBE4E4',
    },
  },
});

export const wrapRootElement = ({ element }) => (
  <ChakraProvider theme={customTheme}>
    <CSSReset />
    {element}
  </ChakraProvider>
);
